
import { Options, Vue } from 'vue-class-component';
import Formwork from '@/components/formwork/formwork.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import {
  ParamArray,
  ParamBoolean,
  ParamNumber,
  ParamObject,
  ParamString,
} from '@/components/formwork/params/param.model';
import SupplierVersion from '@/components/supplier-version.vue';
import { Presupposition } from '../presupposition-list/presupposition.model';
import { FormworkDetailModel } from '../formwork-detail/formwork-detail.model';
@Options({
  components: {
    Formwork,
    ArrowLeftOutlined,
    SupplierVersion,
  },
})
export default class PresuppositionDetail extends Vue {
  // tslint:disable-next-line:variable-name
  public _data: Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > = [new ParamString()];
  public presupposition: Presupposition = new Presupposition();
  public suppositionList: Presupposition[] = [];
  set localData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this._data = value;
  }
  get localData(): Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > {
    return this._data;
  }
  public paramId: string = '';
  public paramName: string = '';
  public currentId: string | undefined;
  public supplier: string = '';
  public type: string = '';
  public version: string = '';
  public industry: string = '';
  public vehicleIds: string[] = [];
  public ifPreview: boolean = false;
  public async mounted() {
    // console.log(this.$route.query);
    const param = this.$route.query;
    this.currentId = param.id as string;
    this.paramId = param.paramId as string;
    this.paramName = param.paramName as string;
    this.vehicleIds = param.vehicleIds
      ? (param.vehicleIds as string).split(',')
      : [];
    this.industry = param.industry as string;
    this.ifPreview = (param.ifPreview as string) === 'true';
    this.getDetail();
    if ((this.vehicleIds && this.vehicleIds.length) || this.industry) {
      this.getSuppositionList();
    }
  }
  public getSuppositionList() {
    this.$http
      .get<Presupposition[]>(`${process.env.VUE_APP_API}/devices/preset`, {
        device_id: this.currentId,
      })
      .then(
        (r) => {
          this.suppositionList = r;
        },
        (e) => {
          this.$base.error(e);
        },
      );
  }
  public async persuppositionChange() {
    this.$base.loading.show();
    const c = await this.getPresupposition();
    this.dealPresupposition([this.localData, this.presupposition]);
    this.$base.loading.close();
  }
  public async getDetail() {
    // console.log('getDetail');
    this.$base.loading.show();
    const a = this.$http
      .get<FormworkDetailModel>(
        `${process.env.VUE_APP_API}/devices/models/${this.currentId}`,
        {},
      )
      .then((r) => {
        // console.log(r);
        this.supplier = r.supplier;
        this.type = r.type;
        this.version = r.version;
        this.localData =
          r.model && r.model !== '{}'
            ? JSON.parse(r.model)
            : [new ParamString()];
        return this.localData;
      });
    if (this.paramId) {
      const b = this.getPresupposition();
      const all = await Promise.all([a, b]).then((r: any[]) => {
        this.dealPresupposition([r[0], r[1]]);
      });
    } else {
      const setModel = (
        param: Array<
          ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray | undefined
        >,
      ) => {
        // console.log('setModel', param);
        param.forEach((val) => {
          if (val && val.type === 'array' && val.arrayType === 'object') {
            val.list.forEach((v) => {
              if (v && typeof v === 'object') {
                setModel([v]);
              }
            });
            val.model = JSON.parse(JSON.stringify(val.list[0]));
            // console.log('model', val.model);
          } else if (val && val.type === 'object') {
            setModel(val.list);
          }
        });
      };
      const all = await Promise.all([a])
        .then((r) => {
          setModel(r[0]);
        })
        .catch((e) => {
          this.$base.error(e);
        });
    }
    this.$base.loading.close();
  }

  public async getPresupposition(): Promise<Presupposition> {
    return this.$http
      .get<Presupposition>(
        `${process.env.VUE_APP_API}/devices/preset/${this.paramId}`,
        {},
      )
      .then((r) => {
        this.paramName = r.name || '';
        this.presupposition = r.preset ? JSON.parse(r.preset) : {};
        return this.presupposition;
      });
  }

  public async dealPresupposition(
    r: [
      Array<
        ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
      >,
      Presupposition
    ],
  ) {
    if (r[0] && r[1]) {
      const dealArray = (array: ParamArray, value: any[] = []): any[] => {
        if (array.arrayType === 'object') {
          array.model = JSON.parse(JSON.stringify(array.list[0]));
          const list: any[] = [];
          if (value && value.length) {
            value.forEach((val) => {
              const target = JSON.parse(JSON.stringify(array.model));
              dealObj(target.list, val);
              list.push(target);
            });
          }
          return list;
        } else if (array.arrayType === 'array') {
          const list: any[] = [];
          (array.list as ParamArray[]).forEach((childArray) =>
            list.push(dealArray(childArray, value)),
          );
          return list;
        } else {
          return array.list;
        }
      };
      const dealObj = (
        model: Array<
          | ParamString
          | ParamNumber
          | ParamBoolean
          | ParamObject
          | ParamArray
          | undefined
        >,
        value: any,
      ): any[] => {
        model.forEach((val, index) => {
          if (val) {
            if (
              val.type === 'string' ||
              val.type === 'number' ||
              val.type === 'boolean'
            ) {
              if (value) {
                const canset =
                  typeof value[val.name] === 'string' ||
                  typeof value[val.name] === 'number' ||
                  typeof value[val.name] === 'boolean';
                val.value =
                  value === undefined || !canset ? undefined : value[val.name];
              } else {
                val.value = undefined;
              }
            } else if (val.type === 'object') {
              if (val.list && val.list.length) {
                val.model = JSON.parse(JSON.stringify(val.list[0]));
              }
              dealObj(
                val.list,
                typeof value[val.name] === 'object'
                  ? value[val.name]
                  : undefined,
              );
            } else {
              val.value = dealArray(
                val,
                typeof value[val.name] === 'object'
                  ? value[val.name]
                  : undefined,
              );
            }
          }
        });
        return [];
      };
      dealObj(r[0], r[1]);
    }
  }

  public async save() {
    // console.log(this.localData);
    this.$base.loading.show();
    if (this.paramId) {
      const preset = this.dealObject(this.localData);
      const a = await this.$http
        .put(`${process.env.VUE_APP_API}/devices/preset/${this.paramId}`, {
          preset: preset ? JSON.stringify(preset) : '',
        })
        .then(() => {
          this.$base.success('保存成功');
        })
        .catch((e) => {
          this.$base.error(e);
        });
    } else {
      if (!this.paramName) {
        this.$base.loading.close();
        return this.$base.error('请先输入预设名称');
      }
      const param: any = {};
      param.supplier = this.supplier;
      param.type = this.type;
      param.version = this.version;
      param.name = this.paramName;
      param.preset = JSON.stringify(this.dealObject(this.localData));
      const a = await this.$http
        .post(`${process.env.VUE_APP_API}/devices/preset`, param)
        .then(() => {
          this.$base.success('新增成功', this.cancel);
        })
        .catch((e) => {
          this.$base.error(e);
        });
    }
    this.$base.loading.close();
  }
  public async issue() {
    this.$base.loading.show();
    const preset = this.dealObject(this.localData);
    const a = await this.$http
      .post(`${process.env.VUE_APP_API}/vehicles/config/set`, {
        vehicle_ids: this.vehicleIds,
        industry_code: this.industry,
        config: preset ? JSON.stringify(preset) : '',
        device_id: this.industry ? this.currentId : undefined,
      })
      .then((r) => {
        this.$base.success('下发成功', this.cancel);
      })
      .catch((e) => {
        this.$base.error(e);
      });
    this.$base.loading.close();
  }

  public dealObject(
    param: Array<
      | ParamString
      | ParamNumber
      | ParamBoolean
      | ParamObject
      | ParamArray
      | undefined
    >,
  ) {
    // console.log('dealObject', param);
    const option: any = {};
    const dealArray = (
      target: string | number | ParamObject | ParamArray | null,
    ): any => {
      // console.log('dealArray', JSON.stringify(target));
      if (target) {
        if (typeof target === 'string' || typeof target === 'number') {
          return [];
        } else if ((target as any).type === 'object') {
          console.log('dealArray-object');
          const list: any[] = [];
          (target.list as ParamObject[]).forEach((r) =>
            list.push(this.dealObject([r])),
          );
          console.log(list);
          return list;
        } else if ((target as any).type === 'array') {
          // console.log('dealArray-array');
          const list: any[] = [];
          (target.list as ParamArray[]).forEach((r) => list.push(dealArray(r)));
          return list;
        }
      } else {
        return [];
      }
    };
    param.forEach(
      (
        obj:
          | ParamString
          | ParamNumber
          | ParamBoolean
          | ParamObject
          | ParamArray
          | undefined,
      ) => {
        if (obj) {
          switch (obj.type) {
            case 'object':
              option[obj.name] = this.dealObject(obj.list);
              break;
            case 'array':
              const list: any[] = [];
              // console.log('array', JSON.stringify((obj as ParamArray).value));
              (obj as any).value.forEach((arrVal: any) =>
                list.push(
                  this.dealObject((arrVal[0] ? arrVal[0] : arrVal).list),
                ),
              );
              option[obj.name] = list;
              break;
            default:
              if (
                obj.value !== undefined &&
                obj.value !== null &&
                obj.value !== ''
              ) {
                option[obj.name] = obj.value;
              }
          }
        }
      },
    );
    // console.log(option);
    return option;
  }

  public writeHtml(box: HTMLElement, obj: object | any[], name?: string) {
    // console.log('obj', obj);
    const objStr = JSON.stringify(obj, null, 4);
    // console.log('objStr', objStr);
    const html = objStr.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp');
    // console.log('html', html);
    box.innerHTML = html;
  }
  public cancel() {
    this.$router.back();
  }
}
