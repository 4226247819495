// tslint:disable:variable-name
export class Presupposition {
    public id: number | undefined;
    public name?: string;
    public supplier: string | undefined;
    public type: string | undefined;
    public version: string | undefined;
    public device_id!: number;
    public preset?: any;
    public update_time: string | undefined;
}
export class GetPresuppositionParam {
    public supplier: string | undefined;
    public type: string | undefined;
    public version: string | undefined;
    // public page_number: number | undefined;
    // public page_size: number | undefined;
}
export class LocalPresupposition {
    public id?: number | undefined;
    public supplier: string | undefined;
    public type: string | undefined;
    public version: string | undefined;
    public device_id!: number;
    public list: Presupposition[] = [];
}
